<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CSidebarNav>
        <template v-for="mItem in menuVisible" >
            <template v-if="mItem.url">
                <CSidebarNavItem :key="mItem.id" >
                    <router-link
                        class="c-sidebar-nav-link" 
                        :to="mItem.url"
                        active-class='c-active'
                    >
                        <template v-if="mItem.isMdiIcon">
                            <my-icon :name="mItem.icon" class="c-sidebar-nav-icon" />  {{mItem.title}}
                        </template>
                        <template v-else>
                            <font-awesome-icon :icon="mItem.icon" class="c-sidebar-nav-icon" /> {{mItem.title}}
                        </template>
                    </router-link>
                </CSidebarNavItem>
            </template>
            <template v-else>
                 <CSidebarNavDropdown :key="mItem.id" :name="mItem.title" :items="[]">

                    <CSidebarNavItem v-for="item in mItem.items" :key="item.id">
                        <router-link
                            class="c-sidebar-nav-link" 
                            :to="item.url"
                            active-class='c-active'
                        >
                            <template v-if="item.isMdiIcon">
                                <my-icon :name="item.icon" class="c-sidebar-nav-icon" />  {{item.title}}
                            </template>
                            <template v-else>
                                <font-awesome-icon :icon="item.icon" class="c-sidebar-nav-icon" /> {{ item.title  }}
                            </template>
                        </router-link>
                    </CSidebarNavItem>
                </CSidebarNavDropdown>
            </template>
        </template>
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>
<script>
import menuJson from '../config/menu.json';
import { library } from '@fortawesome/fontawesome-svg-core'
import nav from './_nav'
import { faUserSecret, faUserTie, faShoppingCart , faTruck, faBarcode, faThLarge, faCalendarDay, faTasks, faBook, faBuilding, faCog, faLayerGroup, faUserCog, faPeopleCarry, faUsers } from '@fortawesome/free-solid-svg-icons'

library.add([faUserSecret, faUserTie, faShoppingCart, faTruck, faBarcode, faThLarge, faCalendarDay, faTasks, faBook, faBuilding, faCog, faLayerGroup, faUserCog, faPeopleCarry, faUsers])

console.log('menuJson', menuJson.menu);

export default {
    name: 'TheSidebar',
    nav,
    data: () => {
        return {
            //menuVisible: [],
            menu: menuJson.menu
        }
    },
    computed: {
        show () {
            return this.$store.state.sidebarShow 
        },
        minimize () {
            return this.$store.state.sidebarMinimize 
        },
        menuVisible() { 
            const self = this;
            const user = JSON.parse(self.$localStorage.get('stock_usuario'));

            console.log('user', user);

            const rolUsuario = user.role.slug;
            const menuVisible = [];

            for(let item of self.menu) {
                console.log(item);

                const nuevoItem = { 
                    id: item.id, 
                    title: item.title, 
                    icon : item.icon, 
                    url: item.url,
                    isMdiIcon: item.isMdiIcon || false,
                    roles: [],
                    items: []
                };

                console.log('nuevoItem', nuevoItem);
                let tienePermiso = false;

                if (item.items) {
                    for(let menuItem of item.items) {
                        console.log('menuItem', menuItem);
                        // let menuItem = item.items[kMenuItem];

                        if (item.roles == undefined) {
                            tienePermiso = true;
                            nuevoItem.items.push(menuItem);
                        } else {
                            if (menuItem.roles.includes(rolUsuario)) {
                                tienePermiso = true;
                                nuevoItem.items.push(menuItem);
                                //delete self.menu[key]['items'][kMenuItem];
                                // delete item['items'][kMenuItem];
                            }
                        }
                    }
                    // if(nuevoItem.items && item.items.lenght > 0) {
                        // delete self.menu[key];
                    // }
                }

                if(item.roles == undefined) {
                    menuVisible.push(nuevoItem);
                } else {
                    //validar si el menu tiene el perfil del usuario logeado
                    if (item.roles.includes(rolUsuario) || tienePermiso) {
                        //delete self.menu[key];
                        menuVisible.push(nuevoItem);
                        // continue;
                    }

                }
                // if (item.items) {
                //     for(let kMenuItem of item.items) {
                //         let menuItem = item.items[kMenuItem];

                //         if (item.roles != undefined && !menuItem.roles.includes(rolUsuario)) {
                //             delete self.menu[key]['items'][kMenuItem];
                //             delete item['items'][kMenuItem];
                //         }
                //     }

                //     if(!item.items || item.items.lenght == 0) {
                //         delete self.menu[key];
                //     }
                // }
            }

            console.log('self.menu', JSON.stringify(self.menu));
            console.log('menuVisible', JSON.stringify(menuVisible));

            return menuVisible;
        }
    },
    methods: {},
    mounted() {
 

        // self.menuVisible = menuVisible;
    }
}
</script>
