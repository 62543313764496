<template>
 <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
          <CIcon name="cil-bell"/>
          <span class="badge badge-danger navbar-badge"> {{ quantity }}</span>
        </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
          {{ quantity }} notificaciones
    </CDropdownHeader>
    <!-- <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right"> -->
        <div class="dropdown-divider"></div>

        <CDropdownItem v-for="item in listado" :key="item.id">
                        <router-link
                            :to="`no`"
                        >
                        <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-1">
                                <span class="<?= $class ?>">
                                    <i class="<?= $notification->notification_type->icon ?> mr-2"></i>
                                </span>
                            </div>
                            <div class="col-11">
                                <div class="row">
                                    <div class="col-12">
                                        <span class="float-left w-100" style="font-size: 14px;">
                                            <?= $notification->message ?>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-6">
                                                <span class="float-left text-muted" style="font-size: 11px;margin-top: -3px;">
                                                    <?= $nombreNormalizado ?>
                                                </span>
                                            </div>
                                            <div class="col-6">
                                                <span class="float-right text-muted" style="font-size: 11px;margin-top: -3px;">
                                                    <?= $notification->timeSince() ?>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                            {{ item.message  }}
                        </router-link>
                    </CDropdownItem>
    <!-- </div> -->

    <!-- <CDropdownItem>
        <a @click="cerrarSession()">

      <CIcon name="cil-lock-locked" /> Cerrar sesión
        </a>
    </CDropdownItem> -->
  </CDropdown>
</template>

<script>

export default {
    name: 'NotificationsDropdown',
    data () {
        return { 
            quantity: 0,
            listado: []
        }
    },
    mounted() {
        const self = this;
        // self.axios.all([
        //     self.axios.get(`notifications/count`, {
        //        params: {
        //             'user-id' : '3ef1cac8-2b2e-497d-b20e-e6d82857e807'
        //         }
        //     }),
        //     self.axios.get(`notifications`, {
        //        params: {
        //             'user-id' : '3ef1cac8-2b2e-497d-b20e-e6d82857e807',
        //             'limit': 10
        //         }
        //     })
        // ])
        // .then(self.axios.spread((dataCount, dataListado) => {
        //     // output of req.
        //     console.log('data2', dataListado);

        //     self.quantity = dataCount.data.count;

        //     self.listado = dataListado.data;
        // })).catch((err) => {
        //     console.log('error', err);
        // });
    },
    methods: {
        
    }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>